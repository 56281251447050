import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
export const GestorModal = ({  dados }) => {

    const [modal,setModal] = useState(false)
    useEffect(()=>{
        setModal(dados?.id?true:false)
    },[dados])
    const modalRef = useRef();

    useEffect(() => {
      // Função para detectar clique fora do modal
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setModal(false); // Fecha o modal se o clique foi fora dele
        }
      };
  
      // Adiciona o evento de clique ao documento
      document.addEventListener('mousedown', handleClickOutside);
  
      // Remove o evento quando o componente é desmontado
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [setModal]);
  
    if (!modal) return null;
    return (
        <Modal
            id="modal-center3"
            size="md"
            isOpen={modal}
            centered={true}
            style={{ maxWidth: 600 }}
        >

            <div className="modal-body" ref={modalRef} >
                <Row >
                    <div className="col-12" >
                        <button type="button"
                            onClick={() => {
                                setModal(false);
                            }} className="close" data-dismiss="modal" aria-label="Close" style={{ float: 'right', marginRight: 10 }} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </Row>

              
                    {dados?.video &&
                      
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <video width={'92%'} height={'400px'} controls>
                                    <source src={dados?.video} type="video/mp4" />
                                </video>
                            </div>
                  
                    }  {dados?.imagem &&
                      
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                <img src={dados?.imagem} width={'100%'} />
                            </div>
                       
                    }
                    {dados?.descricao ?
                        <p style={{ textAlign: 'justify', padding: '10px'}}>
                            {dados?.descricao}
                        </p>
                    : ""}

             




            </div>

        </Modal>
    )
}