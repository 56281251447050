import React, { useEffect, useState } from "react";
import { Col, Label, Modal, Row } from "reactstrap";

export const Filtros = ({ modalFiltro, setModalFiltro, handleCarregamento, municipios, ramo, calculo, setCalculoFiltro }) => {
    const [municipio, setMunicipio] = useState('')
    const [cobertura, setCobertura] = useState('')
    const [coparticipacao, setCoparticipacao] = useState('')
    const [acomodacao, setAcomodacao] = useState('')
    const [contratacao, setContratacao] = useState('')
    const [reembolso, setReembolso] = useState('')


    const handleFiltro = () => {
     
        let filtro = [...calculo]
     
        filtro = filtro.filter((fill) =>
            (municipio ? fill.tabelas.some((fill2) => fill2.areacomercializacaoestadosmunicipios.some((item) => item.id == municipio)) : true) &&
            (cobertura ? fill.tabelas.some((fill2) => fill2.cobertura == cobertura) : true) &&
            (coparticipacao ? fill.tabelas.some((fill2) => fill2.coparticipacao == coparticipacao || ( fill2.coparticipacao == (coparticipacao=='Sim'?'Total':coparticipacao))) : true) &&
            (acomodacao ? fill.tabelas.some((fill2) => fill2.acomodacao == acomodacao) : true) &&
            (contratacao ? fill.tabelas.some((fill2) => fill2.contratacao == contratacao ||  ( fill2.contratacao == (contratacao=='AMBOS'?'Todos':contratacao))) : true) && 
            (reembolso ? fill.tabelas.some((fill2) => fill2.possuereembolso == reembolso) : true) 
        )


        setCalculoFiltro(filtro)
      

    }
    useEffect(() => {
        handleFiltro()
    }, [municipio, cobertura, coparticipacao, acomodacao, contratacao, reembolso])
    return (
        <div  >

            <div className="modal-body" style={{ border: 0 }}>
                <Row style={{ paddingRight: 15, paddingLeft: 15 }}>
                    <Col xs={4} lg={4}>
                        <div>
                            <div className="mb-10"   >
                                
                                <select name="municipio" value={municipio} className="form-control" onChange={(e) => {
                                    setMunicipio(e.target.value == 'Selecione' ? '' : e.target.value)
                                }} >
                                    <option value={''}>Municipios</option>
                                    {municipios && municipios.map((item) => (
                                        <option key={item.id} value={item.id} >{item.nome}</option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4} lg={4}>
                        <div>
                            <div className="mb-3">
                            
                                <select name='cobertura' value={cobertura} className="form-control" onChange={(e) => {
                                    setCobertura(e.target.value == 'Selecione' || e.target.value == 'Todos' ? '' : e.target.value)
                                }} >
                                    <option  value={''}>Cobertura</option>
                                    {/* <option value='Todos' >Todos</option> */}
                                    <option value='Hospitalar' >Hospitalar</option>
                                    <option value='Urgência + Emergência' >Urgência e Emergência</option>
                                    <option value='Ambulatorial' >Ambulatorial</option>
                                 
                                    <option value='Completo (AMB + HOSP + OBST)' >Amb + Hosp Com Obstetrícia</option>
                                    <option value='Completo (AMB + HOSP + OBST) + Odontológico' >Amb + Hosp + Odonto Com Obstetrícia</option>
                                    <option value='Hospitalar + Odontológico' >Hospitalar + Odontológico</option>
                                    <option value='Ambulatorial + Odontológico' >Ambulatorial Com Odonto</option>
                                    <option value='Hospitalar + Obstetrícia' >Hospitalar Com Obstetrícia</option>
                                    <option value='Ambulatorial + Hospitalar' >Amb + Hosp Sem Obstetrícia</option>
                                    <option value='Amb + Hosp + Odonto sem Obstetrícia' >Amb + Hosp + Odonto sem Obstetrícia</option>
                                 
                                </select>
                     


                            </div>
                        </div>
                    </Col>
                    <Col xs={4} lg={4}>
                        <div>
                            <div className="mb-3">
                            
                                <select name="coparticipacao" value={coparticipacao} className="form-control" onChange={(e) => {
                                    setCoparticipacao(e.target.value == 'Selecione' || e.target.value == 'Todos' ? '' : e.target.value)

                                }} >
                                    <option value={''}>Coparticipação</option>
                                    {/* <option value='Todos' >Todos</option> */}
                                    <option value='Sim' >Com Coparticipação</option>
                                    <option value='Não' >Sem Coparticipação</option>
                                    <option value='Parcial' >Com Coparticipação Parcial</option>
                                    {/* <option value='Total' >Total</option> */}
                                </select>

                            </div>
                        </div>
                    </Col>

                    <Col xs={4} lg={4} >
                        <div>
                            <div className="mb-3">
                                <select name="acomodacao" value={acomodacao} className="form-control" onChange={(e) => {
                                    setAcomodacao(e.target.value == 'Selecione' || e.target.value == 'Todos' ? '' : e.target.value)
                                }} >
                                    <option value={''}>Acomodação</option>
                                    <option value='Todos' >Todos</option>
                                    <option value='Ambulatorial' >Ambulatorial</option>
                                    <option value='Enfermaria' >Enfermaria</option>
                                    <option value='Apartamento' >Apartamento</option>
                                </select>

                            </div>
                        </div>
                    </Col>
                    <Col xs={4} lg={4}>
                        <div>
                            <div className="mb-3">
                                <select name="contratacao" value={contratacao} className="form-control" onChange={(e) => {
                                    setContratacao(e.target.value == 'Selecione' || e.target.value == 'Todos' ? '' : e.target.value)
                                }} >
                                    <option value={''}>Contratação</option>
                                    <option value='Todos' >Todos</option>
                                    <option value='OPCIONAL' >Opcional</option>
                                    <option value='COMPULSÓRIO' >Compulsório</option>
                                    {/* <option value='AMBOS' >Ambos</option> */}
                                </select>

                            </div>
                        </div>
                    </Col>
                    <Col xs={4} lg={4} >
                        <div>
                            <div className="mb-3">
                                <select name="possueReembolso" value={reembolso} className="form-control" onChange={(e) => {
                                    setReembolso(e.target.value == 'Selecione' ? '' : e.target.value)
                                }}>
                                    <option value='' >Reembolso</option>
                                    <option value='Sim' >Com Reembolso</option>
                                    <option value='Não' >Sem Reembolso</option>
                                </select>

                            </div>
                        </div>
                    </Col>




                </Row>

            </div>
            {/* <div className="modal-footer" style={{ border: 0 }}>
                <button className="btn btn-primary" onClick={handleFiltro}  >Filtrar</button>
            </div> */}
        </div>



    )
}