import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { Api } from "../../../../api";
import boneco from "../../../../assets/images/icones/boneco.png";
import card from "../../../../assets/images/icones/card.png";
import casa from "../../../../assets/images/icones/casa.png";
import dente from "../../../../assets/images/icones/dente.png";
import megafone from "../../../../assets/images/icones/megafone.png";
import estetoscopio from "../../../../assets/images/icones/estetoscopio.png";
import sino from "../../../../assets/images/icones/sino.png";
import carrinho from "../../../../assets/images/icones/carrinho.png";
import calculadora from "../../../../assets/images/icones/calculo.png";
import mao from "../../../../assets/images/icones/mao.png";
import { Login } from "../Login";
import { MeusDados } from "../MeusDados";
import { googleLogout } from '@react-oauth/google';
import { GoGear } from "react-icons/go";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import { BiDetail } from "react-icons/bi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { RxExit } from "react-icons/rx";
import { IoCarOutline } from "react-icons/io5";
import { BsHouses } from "react-icons/bs";
import { BiBuildingHouse } from "react-icons/bi";
import { SiYourtraveldottv } from "react-icons/si";
import { MdOutlineWorkOutline } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { LuDog } from "react-icons/lu";
import { MdListAlt } from "react-icons/md";
import { CiHospital1 } from "react-icons/ci";
import { MdChecklistRtl } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { FaRegCreditCard } from "react-icons/fa";

import { FaPlaneDeparture } from "react-icons/fa6";
import { PiForkKnifeBold } from "react-icons/pi";
import { LiaAmbulanceSolid } from "react-icons/lia";


import React from "react";
import "./style.css";
import { Parceiro } from "../Parceiro";
import { baseUrlApp } from "../../../../api/baseUrl";
function Drawer({ drawer, action }) {

  const [itemSize, setSize] = useState("");
  const [item, setItem] = useState("");
  const [modalDados, setModalDados] = useState();
  // const handler = (e, value) => {
  //   e.preventDefault();
  //   const getItems = document.querySelectorAll(`#${value} li`).length;
  //   if (getItems > 0) {
  //     if (item !== value) {
  //       setSize(`100%`);
  //       setItem(value);
  //     } else {
  //       setItem(
  //         escolha != 0 && (escolha == 1 || escolha == 2) ? "ferramentos" : ""
  //       );
  //     }
  //   }
  // };
  // const [itemSize, setSize] = useState("0px");
  // const [item, setItem] = useState("");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      if (item !== value) {
        setSize(`${20 * getItems}px`);
        setItem(value);
      } else {
        setItem("");
      }
    }
  };

  const getAuthUser = localStorage.getItem("authUser");

  const navigate = useNavigate();
  const [modalApagar, setModalApagar] = useState();
  const [estadosSaude, setEstadosSaude] = useState();
  const [estadosOdonto, setEstadosOdonto] = useState();
  const [loadingSaude, setLoadingSaude] = useState(false);
  const [loadingOdonto, setLoadingOdonto] = useState(false);
  const [escolha, setEscolha] = useState(0);
  const [escolhasub, setEscolhaSub] = useState(0);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalParceiro, setModalParceiro] = useState(false);
  const handleEstadosSaude = async () => {
    setLoadingSaude(true);
    let list = await Api.estadosSaude();
    setEstadosSaude(list.list);
    setLoadingSaude(false);
  };
  const handleEstadosOdonto = async () => {
    setLoadingOdonto(true);
    let list = await Api.estadosOdontologico();
    setEstadosOdonto(list.list);
    setLoadingOdonto(false);
  };
  useEffect(() => {
    handleEstadosSaude();
    handleEstadosOdonto();
  }, []);
  const handleLink = () => {
    window.open(`${baseUrlApp}/login/${obj.id}/${obj.token}`, "_blank");

  }
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const [comunicados, setComunicados] = useState('')
  useEffect(() => {
    handleDados()
  }, [])
  const obj = getAuthUser == "undefined" || getAuthUser == null ||
    getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(() => {
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
  }, [obj])
  const handleDados = async () => {
    let response = await Api.blogs();
    let list = response?.list?.list
    let id = localStorage.getItem("id");
    if(id){
      id = JSON.parse(id)
    }
    let listagem = list.filter((item)=>(!item?.blogviews?.some((item2)=>(item2?.iduser == id ))))
    setComunicados(listagem?.length)
  };
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${!modalLogin && drawer ? "active" : ""
          }`}
        style={{ width: '100%', marginLeft: '-10px' }}
      ></div>

      <div className="offcanvas_menu" style={{ display: 'block' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${!modalLogin && drawer ? "active" : ""
                  }`}
                style={{ width: !modalLogin && drawer ? "290px" : "" }}
              >
                <a href="/"
                  className="offcanvas-brand text-center mb-40 d-block"
                  style={{ color: '#222222', float: 'right', marginTop: '-30px' }}
                  onClick={(e) => action(e)}  >
                  <span aria-hidden="true" style={{ color: '#222222' }} >&times;</span>
                </a>

                <div id="menu" className="text-left " >
                  <ul className="offcanvas_main_menu">
                    {!obj.nome && (
                      <li className="menu-item-has-children active" style={{ display: 'flex', justifyContent: 'center' }} >
                        {!obj.nome && (
                          <Link style={{ border: 'none' }} onClick={() => {
                             setModalLogin(true); 
                             localStorage.setItem("authPagehist",'/ferramentas-de-venda');
                             }}>
                            <><button className="btn btn-primary" style={{ fontWeight: 100, fontSize: 15, backgroundColor: '#2278F3', border: 'none' }}>Fazer login</button> </>
                          </Link>
                        )}
                      </li>
                    )}
                    {obj.id && (
                      <li onClick={(e) => handler(e, "home")} id="home" className="menu-item-has-children active" >
                        <a href="#" style={{ border: 'none', marginBottom: 0 }}>

                          <span style={{ color: '#092140' }}>Olá, </span><span style={{ textTransform: 'capitalize', color: '#092140' }} >{capitalizeFirstLetter(obj.nomefantasia ? obj.nomefantasia.split(' ')[0] : obj.nome.split(' ')[0])}{" "}</span>


                          <span className="menu-expand" >
                            {item == "home" ?
                              <FaAngleRight />
                              :
                              <FaAngleDown />
                            }
                          </span>
                        </a>

                        <ul className="sub-menu" style={{
                          height: item === "home" ? itemSize : "0px",
                        }} >
                          <li>
                            <Link style={{ color: '#222222', border: 'none', marginBottom: 0 }} onClick={() => { setModalDados(true); }}>
                              <img src={boneco} style={{ width: 13, marginBottom: 4, marginRight: '4px' }} />
                              Meus Dados</Link>
                          </li>
                          {obj.permissao != 5 &&
                            <li >
                              <Link style={{ textAlign: 'left', border: 'none', color: '#222222', marginBottom: 0 }} onClick={handleLink} >
                                <GoGear size={15} style={{ marginRight: 4 }} color="#5c5c5c" />

                                Gerenciar </Link>
                            </li>
                          }

                        </ul>
                      </li>
                    )}


                    <li className="menu-item-has-children active" >
                      <Link to="/" style={{ border: 'none', marginBottom: 0 }} onClick={(e) => {
                        action(e)
                        navigate('/');

                      }

                      } ><img src={casa} style={{ width: 13, marginBottom: 4, marginRight: '1px' }} /> Inicio</Link>
                    </li>

                    <li className="menu-item-has-children active" >
                      <Link to={obj.id ? "/ferramentas-de-venda" : '#'} style={{ marginBottom: 2, border: 'none' }}  onClick={() => { !obj.id && setModalLogin(true) }} >

                      <CiSettings size={15} style={{ marginRight: 2 }} color="#5c5c5c" />
                       
                        Ferramenta de Vendas
                        </Link>
                      {/*   */}
                    </li>


                    <li className="menu-item-has-children active" >
                      <Link to={obj.id ? "/comunicados" : '#'} style={{ marginBottom: 2, border: 'none' }}  onClick={() => { !obj.id && setModalLogin(true) }} >

                        <img src={sino} style={{ width: 15, marginBottom: 4, marginRight: 2 }} />
                        {comunicados?
                          <div style={{ position: 'absolute',left:8, top: '-6px', backgroundColor: 'red', borderRadius: '50%', color: '#fff', width: 10, height: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 9 }} >{comunicados}</div>
                        :""}
                        Comunicados
                        </Link>
                      {/*   */}
                    </li>

                    <li className="menu-item-has-children active" >
                      <Link to="https://api.whatsapp.com/send/?phone=55800060706&text=Quero+ser+um+Corretor+Parceiro&type=phone_number&app_absent=0" style={{ marginBottom: 2, border: 'none' }}  >
                        <MdOutlineWorkOutline size={15} color="#5c5c5c" />  Seja Nosso Parceiro</Link>
                      {/*   */}
                    </li>


                    <li onClick={(e) => handler(e, "tabelaMateriaisVendas")} id="tabelaMateriaisVendas" className="menu-item-has-children active" >
                      <a style={{ color: '#222222', border: 'none', marginBottom: 0 }} > <MdListAlt size={15} style={{ marginRight: 2 }} color="#5c5c5c" />
                       Tabelas e Materiais de Vendas
                        <span className="menu-expand" >
                          {item == "tabelaMateriaisVendas" ?
                            <FaAngleRight />
                            :
                            <FaAngleDown />
                          }
                        </span>
                      </a>

                      <ul className="sub-menu" style={{
                        height: item === "tabelaMateriaisVendas" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active" >
                          <Link to={"/plano/saude"}  style={{ border: 'none', marginBottom: 2 }}>  <img src={estetoscopio} style={{ width: 13, marginBottom: 4, marginRight: '5px' }} />Planos de Saúde</Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={"/plano/odontologico" }  style={{ border: 'none', marginBottom: 2 }}><img src={dente} style={{ width: 13, marginBottom: 4 }} /> Planos Odontológicos</Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={"/plano/odontologico" }  style={{ border: 'none', marginBottom: 2 }}>
                          
                          <LiaAmbulanceSolid style={{marginRight:3}} />
                      APH Emergências Médicas 24h</Link>
                        </li>
                        
                        <li className="menu-item-has-children active">
                          <Link to={"/plano/pet" }  style={{ border: 'none', marginBottom: 2 }}>
                          <LuDog style={{marginRight:3}} />
                           Planos de Saúde Pet</Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={"/plano/pah" }  style={{ border: 'none', marginBottom: 2 }}>
                          <FaRegCreditCard style={{marginRight:3}} />
                          Cartões de Benefícios
                         </Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={"/plano/rh" }  style={{ border: 'none', marginBottom: 2 }}>
                          <PiForkKnifeBold style={{marginRight:3}} />
                           PAT e Benefícios para RH</Link>
                        </li>
                       
                        {/* <li className="menu-item-has-children active">
                          <Link to={"/plano/consorcio" }  style={{ border: 'none', marginBottom: 2 }}>
                          <GrMoney style={{marginRight:3}} />
                           Consórcios</Link>
                        </li> */}
                      
                        <li className="menu-item-has-children active" >
                          <Link to={obj.id ? "/materiais" : '#'} style={{ marginBottom: 2, border: 'none' }} onClick={() => { 
                               if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/materiais');
                              } 
                          }}  >
                            <img src={megafone} style={{ width: 13, marginBottom: 2, marginRight: '5px' }} />
                            Materiais de Divulgação</Link>
                        </li>


                      </ul>
                    </li>


                    <li onClick={(e) => handler(e, "cotacoes")} id="cotacoes" className="menu-item-has-children active" >
                      <a style={{ border: 'none', color: '#222222', marginBottom: 0 }} ><img src={calculadora}  style={{ width: 15, marginBottom: 4, marginRight: 2 }} />Faça Suas Cotações
                        <span className="menu-expand" >
                          {item == "cotacoes" ?
                            <FaAngleRight />
                            :
                            <FaAngleDown />
                          }
                        </span>
                      </a>
                      <ul className="sub-menu" style={{
                        height: item === "cotacoes" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active">
                          <a href={obj.id ? `/cotacoes/saude` : '#'} style={{ border: 'none', marginBottom: 2 }} onClick={() => { 
                              if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/cotacoes/saude');
                              }  }}
                          ><img src={estetoscopio} style={{ width: 13, marginBottom: 4 }} /> Multicálculo Saúde</a>
                        </li>
                        <li className="menu-item-has-children active">
                          <a href={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2 }} onClick={() => { 
                              if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/cotacoes/odontologico');
                              }
                            }}
                          ><img src={dente} style={{ width: 13, marginBottom: 4 }} /> Multicálculo Odonto</a>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/tabela` : '#'} style={{ border: 'none', marginBottom: 2 }} onClick={() => {
                            if(!obj.id){
                              setModalLogin(true)
                              localStorage.setItem("authPagehist",'/tabela');
                            } }}
                          ><img src={card} style={{ width: 13, marginBottom: 4, border: 'none' }} /> Tabelas Personalizadas</Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2 }} onClick={() => {
                              if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/cotacoes/odontologico');
                              }
                          }}>

                            <IoCarOutline size={15} style={{ marginRight: 4 }} color="#5c5c5c" />
                            Multicálculo Auto e Moto</Link>
                        </li>


                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2 }} onClick={() => {
                              if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/cotacoes/odontologico');
                              }
                          }}>
                            <BsHouses size={15} style={{ marginRight: 4 }} color="#5c5c5c" />Multicálculo Residencial</Link>
                        </li>

                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2 }} onClick={() => {   if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/cotacoes/odontologico');
                              } }}
                          >
                            <BiBuildingHouse size={15} style={{ marginRight: 4 }} color="#5c5c5c" /> Multicálculo Condomínio
                          </Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={obj.id ? `/cotacoes/odontologico` : '#'} style={{ border: 'none', marginBottom: 2 }} onClick={() => {  if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/cotacoes/odontologico');
                              } }}
                          >
                            <FaPlaneDeparture size={15} style={{ marginRight: 4 }} color="#5c5c5c" />
                            Seguro Viagem</Link>
                        </li>
                      </ul>
                    </li>

                    <li onClick={(e) => handler(e, "vendas")} id="vendas" className="menu-item-has-children active" >
                      <a style={{ border: 'none', color: '#222222', marginBottom: 0 }} ><img src={carrinho}  style={{ width: 15, marginBottom: 4, marginRight: 2 }} /> Links de Vendas Online
                        <span className="menu-expand" >
                          {item == "vendas" ?
                            <FaAngleRight />
                            :
                            <FaAngleDown />
                          }
                        </span>
                      </a>
                      <ul className="sub-menu" style={{
                        height: item === "vendas" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active">
                          <Link to={`/vendaOnline`}  style={{ border: 'none', marginBottom: 2 }} ><img src={estetoscopio} style={{ width: 13, marginBottom: 2 }} /> Planos de Saúde</Link>
                        </li>
                        <li className="menu-item-has-children active">
                          <Link to={`/vendaOnline`}  style={{ border: 'none' }}  ><img src={dente} style={{ width: 13, border: 'none', marginBottom: 2, borderBottom: 'none' }} /> Planos Odontológicos</Link>
                        </li>
                      </ul>
                    </li>


                    <li onClick={(e) => handler(e, "produtos")} id="produtos" className="menu-item-has-children active" >
                      <a style={{ border: 'none', color: '#222222', marginBottom: 0 }} ><img src={mao}  style={{ width: 15, marginBottom: 4, marginRight: 2 }} /> Produtos e Empresas
                        <span className="menu-expand" >
                          {item == "produtos" ?
                            <FaAngleRight />
                            :
                            <FaAngleDown />
                          }
                        </span>
                      </a>
                      <ul className="sub-menu" style={{
                        height: item === "produtos" ? itemSize : "0px",
                      }} >
                        <li className="menu-item-has-children active" >
                          <Link to={"/ramos"} style={{ border: 'none', marginBottom: 2 }} onClick={() => { 
                              if(!obj.id){
                                setModalLogin(true)
                                localStorage.setItem("authPagehist",'/ramos');
                              }
                          }}  >
                            <BiDetail style={{ marginRight: '2px' }} />
                            Produtos e Ramos de Atuação</Link>
                        </li>

                        <li className="menu-item-has-children active" >
                          <Link to={ "/operadoras"} style={{ marginBottom: 2, border: 'none' }} onClick={() => { 
                            if(!obj.id){
                              setModalLogin(true)
                              localStorage.setItem("authPagehist",'/operadoras');
                            }
                            }}  >
                            <HiOutlineBuildingOffice2 style={{ marginRight: '2px' }} />
                            Empresas Parceiras</Link>
                        </li>

                      </ul>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={() => {
                          localStorage.setItem("authUser", "{}");
                          window.location.href = '/'
                          googleLogout();
                        }}
                        style={{ textAlign: 'left', color: '#222222', marginBottom: 0, border: 'none' }}
                      >
                        <RxExit size={15} style={{ marginRight: 4 }} color="#5c5c5c" />


                        Sair
                      </a>
                    </li>



                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
      <Parceiro modalDados={modalParceiro} setModalDados={setModalParceiro} />
      <MeusDados modalDados={modalDados} setModalDados={setModalDados} />
    </>
  );
}

export default Drawer;
