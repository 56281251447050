import React, { useEffect, useState } from "react";
import { Modal, Row } from "reactstrap";
import carro from "../../assets/images/icones-venda/carro.png";
import casa from "../../assets/images/icones-venda/casa.png";
import documento from "../../assets/images/icones-venda/documento.png";
import pessoa from "../../assets/images/icones-venda/pessoa.png";
import odonto from "../../assets/images/icones-venda/iconeOdonto.png";
import lista from "../../assets/images/icones-venda/lista.png";
import redes from "../../assets/images/icones-venda/redes.png";
import saude from "../../assets/images/icones-venda/saude.png";
import seguroVida from "../../assets/images/icones-venda/seguro-vida.png";
import viagem from "../../assets/images/icones-venda/viagem.png";
import { Login } from "../Corpo/Header/Login";
import { Api } from "../../api";
import { GestorModal } from "../components/modal";
export const AreaItem = ({ title, subTitle, titleModal, icon, href, showPopover, togglePopover, togglePopoverOut, index, setModalLogin, id,iframe,setModalIframe,handleIframe,widthIMG,height }) => {

  return (
    <div onClick={()=>{handleIframe(index)}} className="col-6 col-lg-3 col-sm-4 " style={{ marginBottom:20, borderRadius: '20px', border: showPopover[index] ? '1px solid #ccc' : 'none' }} onMouseOver={() => { togglePopover(index) }} onMouseOut={() => { togglePopoverOut(index) }}>
      <div className=" wow fadeInUp delay-0-4s" style={{textAlign: 'center',display:'flex',justifyContent:'flex-start',alignItems:"center",flexDirection:"column" ,height:height?height:'100%' }}>
        <div className="icon">
          <a onClick={() => { id ? iframe?setModalIframe(true) : setModalLogin(true): setModalLogin(true) }} href={ href?id && iframe?null: href:'#'} >
            <img
              src={icon}
              style={{ width:widthIMG?widthIMG:"100px", textAlign: 'center' }}
            />
          </a>

        </div>
        <p style={{fontWeight:'none'}} >
          <a onClick={() => {href?id ? iframe?setModalIframe(true) : setModalLogin(true): setModalLogin(true):'' }} href={ href? id ? iframe?null: href:null:'#'} style={{ fontSize:14, fborderBottom: showPopover[index] ? '1px solid #ccc' : 'none',color: '#1B2949' }}>{title} </a>
        </p>
        <p style={{marginTop:'-16px',fontWeight: 100,fontSize:14, color: '#1B2949'}} >{subTitle}</p>

        {showPopover[index] && <AreaModal title={titleModal} />}

      </div>
    </div>
  )

}
export const AreaModal = ({ title }) => {
  return (
    <div style={{ position: 'absolute',borderRadius:6, top: '-100px', width: '100%', left: 0, backgroundColor: 'white', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', color: '#092140', height: 100, verticalAlign: 'middle', display: 'flex', justifyContent: 'center', alignItems: 'center',padding:10 }}  >
      <p style={{ color: '#092140', opacity: '1' ,fintSize:12}} dangerouslySetInnerHTML={{ __html: title }}></p>
    </div>
  )
}
function ServiceSection() {
  const [modalIframe, setModalIframe] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [iframeArray, setIframeArray] = useState();
  const [iframeSelect, setIframeSelect] = useState();
  const [titleIframe,setTitleIframe] = useState('')
  const getAuthUser = localStorage.getItem("authUser");
  const obj =
    getAuthUser == "undefined" ||
      getAuthUser == null ||
      getAuthUser == "[object Object]"
      ? ""
      : JSON.parse(getAuthUser ? getAuthUser : "");
  useEffect(() => {
    const obj =
      getAuthUser == "undefined" ||
        getAuthUser == null ||
        getAuthUser == "[object Object]"
        ? ""
        : JSON.parse(getAuthUser ? getAuthUser : "");

  }, [obj])
  const [showPopover, setShowPopover] = useState([false, false, false, false]);
  useEffect(() => {
  
  }, []);
  const togglePopover = (id) => {
    let Popover = [...showPopover]
    Popover[id] = true
    setShowPopover(Popover);
  };
  const togglePopoverOut = (id) => {
    let Popover = [...showPopover]
    Popover[id] = false
    setShowPopover(Popover);
  };
  useEffect(()=>{
    let dados = [
      {id:6,nome:'Seguros de Vida',url:'https://natuseg.corretagemfacil-app.com.br/outros_ramos/vida/new?filial=48778'},
      {id:7,nome:'Seguro Viagem',url:'https://natuseg.corretagemfacil-app.com.br/viagem/new?filial=48778'},
      {id:8,nome:'Seguro de Automóveis',url:'https://natuseg.corretagemfacil-app.com.br/outros_ramos/transporte/new?filial=48778'},
      {id:9,nome:'Seguro e Assistência',url:'https://natuseg.corretagemfacil-app.com.br/outros_ramos/garantia/new?filial=48778'},
      {id:10,nome:'Seguro Garantia',url:'https://natuseg.corretagemfacil-app.com.br/viagem/new?filial=48778'},
      {id:11,nome:'Seguro RC Profissional',url:'https://natuseg.corretagemfacil-app.com.br/outros_ramos/rc-geral/new?filial=48778'},
    ]
    setIframeArray(dados)
    handleModal()
  },[])
  const handleIframe = (id) =>{
    let buscar = iframeArray.find((item)=>item.id ==id)
    setTitleIframe(buscar.nome)
    setIframeSelect(buscar.url)
  }
  const [dados, setDados] = useState([]);
  const handleModal = async () => {
    let list = await Api.gestorModal('Ferramentas');
    setDados(list.dados)
  
  };
  return (
    <div className="pt-30  pb-70 ">

      <div className="container" style={{ color: '#C7C7C7' }}>
        <div className="section-title-with-btn " style={{ borderBottom: '1px solid #ccc', borderBottom: '1px solid transparent', borderImage: 'linear-gradient(to right, white, lightgray, gray, black, gray, lightgray, white)', borderImageSlice: 1,marginBottom:40 }}>
          <div className="section-title" style={{ width: '100%', textAlign: 'center' }} >
            <h2 style={{ color: '#1B2949', fontSize: 20, fontWeight: 100 }}>Faça suas Cotações e Simulações</h2>
          </div>
        </div>
        <div className="row" style={{ fontWeight: 100, marginTop: '-80px',display:'flex',justifyContent:'center',alignItems:'center' ,marginTop:20}}>

          <AreaItem title={`Multicálculo`} subTitle={`Planos de Saúde`} titleModal={`Simule <b>Planos de Saúde</b> para seus clientes em segundos`} icon={saude} href={"/cotacoes/saude"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={0} id={obj.id} handleIframe={()=>{}} />

          <AreaItem title={`Multicálculo`} subTitle={`Planos Odontológicos`} titleModal={`Simule <b>Planos Odontológicos</b> para seus clientes em segundos`} icon={odonto} href={"/cotacoes/odontologico"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={1} id={obj.id} handleIframe={()=>{}} />

          <AreaItem title={`Tabelas de Preços`} subTitle={`Personalizadas`} titleModal={`Crie <b>Tabelas de Preços</b> como preferir e com seu próprio <b>logotipo</b>`} icon={lista} href={"/tabela"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={2} id={obj.id} handleIframe={()=>{}} />

          <AreaItem title={`Comparador de Rede`} subTitle={`Planos de Saúde`} titleModal={`Em Breve`} icon={redes} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} setModalIframe={()=>{}} index={3} id={obj.id} handleIframe={()=>{}} />



        </div>
        <div className="section-title-with-btn" style={{ borderBottom: '1px solid #ccc', borderBottom: '1px solid transparent', borderImage: 'linear-gradient(to right, white, lightgray, gray, black, gray, lightgray, white)', borderImageSlice: 1 ,marginBottom:40,marginTop: 20}}>
          <div className="section-title" style={{ width: '100%', textAlign: 'center' }} >
            <h2 style={{ color: '#1B2949', fontSize: 20, fontWeight: 100 }}>Links de Vendas Online</h2>
          </div>
        </div>
        <div className="row" style={{ fontWeight: 100, marginTop: '-80px',display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap'  ,marginTop:20}}>

          <AreaItem title={`Planos de Saúde`} subTitle={`Individual, Adesão e PME`} titleModal={`Feche suas vendas totalmente Online`} icon={saude} href={"/vendaOline/saude"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={4} id={obj.id} handleIframe={()=>{}}  />

          <AreaItem title={`Planos Odontológicos`} subTitle={`Individual e PME`} titleModal={`Feche suas vendas totalmente Online`} icon={odonto} href={"/vendaOline/odonto"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={5} id={obj.id} handleIframe={()=>{}}  />

          <AreaItem title={`Seguros de Vida`} subTitle={`e Acidentes`} titleModal={`Feche suas vendas totalmente Online`} icon={seguroVida} href={"/"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={6} id={obj.id} iframe={true} setModalIframe={setModalIframe} handleIframe={handleIframe}   />

          <AreaItem title={`Seguro Viagem`} subTitle={`Nacional e Internacional`} titleModal={`Feche suas vendas totalmente Online`} icon={viagem} href={"/"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={7} id={obj.id} iframe={true} setModalIframe={setModalIframe} handleIframe={handleIframe}   />

          <AreaItem title={`Seguro de Automóveis`} widthIMG={54} subTitle={`Motos e Caminhões`} titleModal={`Feche suas vendas totalmente Online`} icon={carro} href={"/"} showPopover={showPopover} togglePopoverOut={togglePopoverOut}  togglePopover={togglePopover} index={8} id={obj.id} iframe={true} setModalIframe={setModalIframe} handleIframe={handleIframe}   />

          <AreaItem title={`Seguro e Assistência`} widthIMG={54} subTitle={`Residencial`} titleModal={`Feche suas vendas totalmente Online`} icon={casa} href={"/"} showPopover={showPopover} togglePopoverOut={togglePopoverOut}  togglePopover={togglePopover} index={9} id={obj.id} iframe={true} setModalIframe={setModalIframe} handleIframe={handleIframe} />

          <AreaItem title={`Seguro Garantia`} widthIMG={54} subTitle={`Licitações e Judicial`} titleModal={`Feche suas vendas totalmente Online`} icon={documento} href={"/"} showPopover={showPopover} togglePopoverOut={togglePopoverOut}  togglePopover={togglePopover} index={10} id={obj.id} iframe={true} setModalIframe={setModalIframe} handleIframe={handleIframe}  />

          <AreaItem title={`Seguro`} widthIMG={54} subTitle={`RC Profissional`} titleModal={`Feche suas vendas totalmente Online`} icon={pessoa} href={"/"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover}  index={11} id={obj.id} iframe={true} setModalIframe={setModalIframe} handleIframe={handleIframe}  />

        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />

      <Modal isOpen={modalIframe} size='lg' centered={false}>
          <div className="modal-header">
          <p>{titleIframe}</p>
            <button type="button" onClick={() => { setModalIframe(false)}} className="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          
          </div>
          <div className="modal-body">
            <Row>
              <iframe id="test" width="100%" height={'450px'} src={iframeSelect}></iframe>
            </Row>
          </div>
      </Modal>
      <GestorModal dados={dados} />
    </div>
  );
}
export default ServiceSection;