import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { Api } from "../../api/apiCotacoes";
import { Agendamentos } from "./utils/agendamentos";
import Agendar from "./utils/agendar";
import { Cadastro } from "./utils/cadastro";
import Editar from "./utils/editar";
import { Excluir } from "./utils/excluir";
import { Filtros } from "./utils/filtros";
import { useParams } from "react-router-dom";
import { Visualizar } from "./utils/visualizar";
import { VisualizarDetalhe } from "./utils/visualizarDetalhes";
import { VisualizarSelecionado } from "./utils/visualizarSelecionado";
import Icone from "../../assets/icones/icone.png"
import { VisualizarSelecionadoTodos } from "./utils/visualizarSelecionadoTodos";
function CotacoesPrecos() {
  document.title = "Corretor Parceiro";
  const [ramo, setRamo] = useState("");
  useEffect(() => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("saude")) {
      setRamo("2");
    } else if (url.includes("odontologico")) {
      setRamo("1");
    }
  }, []);
  const [resposta, setResposta] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [dados, setDados] = useState();
  const [info, setInfo] = useState();
  const [estados, setEstados] = useState([]);
  const [areaComercializacao, setAreaComercializacao] = useState([]);
  const [modalVisualizarDetalhe, setModalVisualizarDetalhe] = useState(false);
  const [profissoes, setProfissoes] = useState();
  const [profissoesSelect, setProfissoesSelect] = useState();
  const [salvarVisualizar, setSalvarVisualizar] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [modalExcluir, setModalExcluir] = useState(false);
  const [modalVisualizarOperadora, setModalVisualizarOperadora] =
    useState(false);
  const [buscar, setBuscar] = useState("");
  const [list, setList] = useState([]);
  const [obj, setObj] = useState();


  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    const obj = getAuthUser == "undefined" || getAuthUser == null || getAuthUser == "[object Object]" ? "" : JSON.parse(getAuthUser ? getAuthUser : "");
    setObj(obj)
  }, [])

  const [editar, setEditar] = useState(false);
  const [itens, setItens] = useState([{}]);
  const [faixas, setFaixas] = useState([]);
  const [calculo, setCalculo] = useState([]);
  const [calculoDetalhe, setCalculoDetalhe] = useState([]);
  const [infoDependente, setInfoDependente] = useState([]);
  const [infoSelecionado, setInfoSelecionado] = useState({});
  const [
    modalVisualizarDetalheSelecionado,
    setModalVisualizarDetalheSelecionado
  ] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalFiltro, setModalFiltro] = useState(false);
  const [modalAgendar, setModalAgendar] = useState(false);
  const [modalAgendamento, setModalAgendamento] = useState(false);
  const [modalVisualizarDetalheSelecionadoTotal, setModalVisualizarDetalheSelecionadoTotal] = useState(false);
  const [selectedsTotal, setSelectedsTotal] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [filtro1, setFiltro1] = useState("");
  const [filtro2, setFiltro2] = useState("");
  const [filtro3, setFiltro3] = useState("");
  const [filtro4, setFiltro4] = useState("");
  const [filtro5, setFiltro5] = useState("");
  const [filtro6, setFiltro6] = useState("");
  const [loadingDetalheSelecionado, setLoadingDetalheSelecionado] = useState(false)
  const [calculoFiltro,setCalculoFiltro] = useState([])
  useEffect(() => {
    Listagem();

    handleProfissoes();
    handleFaixaValue();
    handleAreaComercializacao();
    handleMunicipios();
  }, []);
  const handleMunicipios = async () => {
    let option = await Api.municipio();
    setMunicipios(option.option);
  };
  const handleCarregamento = (status) => {
    let body = document.querySelector("body");
    if (status) {

      if (body) {
        setLoading(true)
        body.style.cursor = "wait";
      }
    } else {
      if (body) {
        setLoading(false)
        body.style.cursor = "";
      }
    }
  };
  const handleStatus = async (id, status) => {
    let list = await Api.status({ id: id, status });
    setList(list.list);
  };
  const handleAreaComercializacao = async () => {
    let dados = await Api.areacomercializacao();
    setAreaComercializacao(dados.option);
  };
  const handleEstados = async () => {
    let option = await Api.estados({ tipoOperadoras: validation.values.tipoOperadora });
    setEstados(option);
  };

  const handleProfissoes = async () => {
    let dados = await Api.profissoes();
    let val = dados.option;
    val?.unshift({ nome: "Sem profissão", id: "" });
    setProfissoes(val);
    setProfissoesSelect([]);
  };
  const handleModifica = (index, val) => {
    let item = itens;
    if (item[index] || item[index] == "") {
      item[index] = val;
    } else {
      item.push(val);
    }
    setItens(item);
    setEditar(!editar);
  };
  const handleAdd = (val) => {

    let item = [...itens];
    item.push({});
    setItens(item);

  };
  const handleExit = (index) => {
    let item = [...itens];
    item.splice(index, 1);
    setItens(item);
    setEditar(!editar);
  };
  const handleFaixasValue = (value, index) => {
    let faixa = faixas;
    faixa[index].value = value;
    setFaixas(faixa);
    setEditar(!editar);
  };
  const handleFaixaValue = async () => {
    let list = await Api.faixas({ id: 8 });
    let listFaixas = list
    listFaixas = listFaixas?.filter((item, index) => index != 0);
    setFaixas(listFaixas);
  };
  const handleTipoValue = async (dados) => {
    handleModifica(dados.item, dados.val);
  };

  useEffect(() => {
    if (id) {
      handleCalculo(id)
    }
  }, [id])
  const [loadingDetalhes, setLoadingDetalhe] = useState(false)
  const handleOperadora = async (item, status = true) => {
    setModalVisualizarDetalhe(status);
    setLoadingDetalhe(true);


    item.tabelas?.sort((a, b) =>
      a.tabelafaixas.length > 0 ?
        Number(a?.tabelafaixas?.sort((a1, b1) => Number(a1?.valor?.replace('.', '')?.replace(',', '.')) - Number(b1?.valor?.replace('.', '')?.replace(',', '.'))).reduce((menor, item) => {
          return item.valor < menor.valor ? item : menor;
        })?.valor?.replace(',', '.')?.replace(',', '.'))

        - Number(b?.tabelafaixas?.sort((a1, b1) => Number(a1?.valor?.replace('.', '')?.replace(',', '.')) - Number(b1?.valor?.replace('.', '')?.replace(',', '.'))).reduce((menor, item) => {
          return item.valor < menor.valor ? item : menor;
        })?.valor?.replace(',', '.')?.replace(',', '.'))
        : Number(a.precovida?.replace(',', '.')?.replace(',', '.')) - Number(b.precovida?.replace(',', '.')?.replace(',', '.'))
    )

    await gerarTabela(item, dados?.array, status);
    setLoadingDetalhe(false);
    return true
  };

  const gerarTabela = async (item, dependentes, status = true) => {

    let array = {
      produto: [],
      faixas: [],
      observacoes: [],
      areacomercializacao: [],
      dependente: [],
      documento: [],
      pagamento: [],
      rede: [],
      reembolso: [],
      carencia: [],
      entidades: [],
      coparticipacoes: [],
      imagem: item?.imagem,
      operadora: item?.nome
    };

    item.tabelas?.map((item) => {

      array.produto.push({
        id: item.id, nome: item.nome, cobertura: item.cobertura, acomodacao: item.acomodacao, coparticipacao: item.coparticipacao,possuereembolso:item.possuereembolso,
        percentual: item.percentual, vidas: `${item.minvidas} a ${item.maxvidas}`, faixas: item.tabelafaixas.sort((pessoa1, pessoa2) => pessoa1.id - pessoa2.id),
        precovida: item.precovida, item, status: item.status
      });
      if (item.coparticipaccao?.id) {
        if (item.coparticipaccao?.id && !array.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id)) {
          array.coparticipacoes.push({ id: item.coparticipaccao.id, item: item.coparticipaccao, produto: [item.nome] })
        } else {
          array.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id ? arr.produto.push(item.nome) : '')
        }
      }
      if (item.observacao?.id && !array.observacoes.some((arr) => arr.id == item.observacao.id)) {
        array.observacoes.push({ id: item.observacao?.id, item: item.observacao, produto: [item.nome] });
      } else {
        array.observacoes.some((arr) =>
          arr.id == item.observacao?.id ? arr.produto.push(item.nome) : ""
        );
      }
      if (item.areacomercializacao?.id && !array.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id)) {
        array.areacomercializacao.push({
          id: item.areacomercializacao.id, descricoes: item.areacomercializacao?.areacomercializacaodescricaos, produto: [item.nome], municipios: item.areacomercializacaoestadosmunicipios
        });
      } else {
        array.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id ? arr.produto.push(item.nome) : "");
      }
      if (item.dependente?.id && !array.dependente.some((arr) => arr.id == item.dependente?.id)) {
        array.dependente.push({ id: item.dependente?.id, item: item.dependente, produto: [item?.nome] });
      } else {
        array.dependente.some((arr) => arr.id == item.dependente?.id ? arr.produto.push(item.nome) : ""
        );
      }
      if (item.documento?.id && !array.documento.some((arr) => arr.id == item.documento?.id)) {
        array.documento.push({ id: item.documento.id, item: item.documento, produto: [item.nome] });
      } else {
        array.documento.some((arr) => arr.id == item.documento?.id ? arr.produto.push(item.nome) : "");
      }

      if (item.pagamento?.id && !array.pagamento.some((arr) => arr.id == item.pagamento?.id)
      ) {
        array.pagamento.push({ id: item.pagamento.id, item: item.pagamento, produto: [item.nome] });
      } else {
        array.pagamento.some((arr) => arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : "");
      }

      if (item.carencia?.id && !array.carencia.some((arr) => arr.id == item.carencia?.id)) {
        array.carencia.push({
          id: item.carencia.id, item: item.carencia, produto: [item.nome]
        });
      } else {
        array.carencia.some((arr) => arr.id == item.carencia?.id ? arr.produto.push(item.nome) : "");
      }

      item.tabelafaixas.map((item2) => {
        if (item2.rede?.id && !array.rede.some((arr) => arr.id == item2.rede?.id)) {
          array.rede.push({ id: item2.rede.id, item: item2.rede, produto: [item.nome] });
        } else {
          array.rede.some((arr) => arr.id == item2.rede?.id ? (!arr.produto.some((prod) => prod == item.nome) ? arr.produto.push(item.nome) : '') : "");
        }
      })


      if (item.reembolso?.id && !array.reembolso.some((arr) => arr.id == item.reembolso?.id)) {
        array.reembolso.push({ id: item.reembolso.id, item: item.reembolso, produto: [item.nome] });
      } else {
        array.reembolso.some((arr) => arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : "");
      }
      if (item.grupoentidades?.id && !array.entidades.some((arr) => arr.id == item.grupoentidades?.id)
      ) {
        array.entidades.push({ id: item.grupoentidades.id, item: item.grupoentidades, produto: [item.nome] });
      } else {
        array.entidades.some((arr) => arr.id == item.grupoentidades?.id ? arr.produto.push(item.nome) : "");
      }
    });


    setInfoDependente(dependentes);
    setInfo(array);

    handleCarregamento(false);
    return true
  };
  const gerarTabelaSelecionada = (dados, status = false) => {

    // let array = {
    //   nome: "",
    //   id: calculoDetalhe?.id,
    //   estado: calculoDetalhe?.estado,
    //   produto: [],
    //   faixas: [],
    //   observacoes: [],
    //   areacomercializacao: [],
    //   dependente: [],
    //   documento: [],
    //   pagamento: [],
    //   rede: [],
    //   reembolso: [],
    //   carencia: [],
    //   entidades: [],
    //   coparticipacoes: []
    // };
    let array = []

    dados.filter((fil) => fil.status == 1)?.map((tabela) => {
      array.push({
        visualizar: true, id: tabela.id, operadora: tabela.nome, imagem: tabela.imagem,
        tabelas: {
          nome: "",
          id: calculoDetalhe?.id,
          estado: calculoDetalhe?.estado,
          produto: [],
          faixas: [],
          observacoes: [],
          areacomercializacao: [],
          dependente: [],
          documento: [],
          pagamento: [],
          rede: [],
          reembolso: [],
          carencia: [],
          entidades: [],
          coparticipacoes: []
        }
      })
      tabela.tabelas?.map((item) => {
        let indexOperado = array.findIndex((arrayItem) => arrayItem.id == tabela.id)

        if (status ? status : selecteds.some((item3) => item3.idtabela === item.id)) {


          array[indexOperado].tabelas.produto.push({
            id: item.id, nome: item.nome, cobertura: item.cobertura, acomodacao: item.acomodacao, coparticipacao: item.coparticipacao,possuereembolso:item.possuereembolso,
            percentual: item.percentual, vidas: `${item.minvidas} a ${item.maxvidas}`, faixas: item.tabelafaixas.sort((pessoa1, pessoa2) => pessoa1.id - pessoa2.id),
            precovida: item.precovida, item, status: item.status
          });
          if (item.coparticipaccao?.id) {
            if (item.coparticipaccao?.id && !array[indexOperado].tabelas.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id)) {
              array[indexOperado].tabelas.coparticipacoes.push({ id: item.coparticipaccao.id, item: item.coparticipaccao, produto: [item.nome] })
            } else {
              array[indexOperado].tabelas.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id ? arr.produto.push(item.nome) : '')
            }
          }
          if ( item.observacao?.id && !array[indexOperado].tabelas.observacoes.some((arr) => arr.id == item.observacao.id) ) {
            array[indexOperado].tabelas.observacoes.push({ id: item.observacao?.id, item: item.observacao, produto: [item.nome] });
          } else {
            array[indexOperado].tabelas.observacoes.some((arr) =>
              arr.id == item.observacao?.id ? arr.produto.push(item.nome) : ""
            );
          }
          if (item.areacomercializacao?.id && !array[indexOperado].tabelas.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id)) {
            array[indexOperado].tabelas.areacomercializacao.push({
              id: item.areacomercializacao.id, descricoes: item.areacomercializacao?.areacomercializacaodescricaos, produto: [item.nome], municipios: item.areacomercializacaoestadosmunicipios
            });
          } else {
            array[indexOperado].tabelas.areacomercializacao.some((arr) =>
              arr.id == item.areacomercializacao?.id
                ? arr.produto.push(item.nome)
                : ""
            );
          }
          if (
            item.dependente?.id &&
            !array[indexOperado].tabelas.dependente.some((arr) => arr.id == item.dependente?.id)
          ) {
            array[indexOperado].tabelas.dependente.push({
              id: item.dependente?.id,
              item: item.dependente,
              produto: [item?.nome]
            });
          } else {
            array[indexOperado].tabelas.dependente.some((arr) =>
              arr.id == item.dependente?.id ? arr.produto.push(item.nome) : ""
            );
          }
          if (
            item.documento?.id &&
            !array[indexOperado].tabelas.documento.some((arr) => arr.id == item.documento?.id)
          ) {
            array[indexOperado].tabelas.documento.push({
              id: item.documento.id,
              item: item.documento,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.documento.some((arr) =>
              arr.id == item.documento?.id ? arr.produto.push(item.nome) : ""
            );
          }

          if (
            item.pagamento?.id &&
            !array[indexOperado].tabelas.pagamento.some((arr) => arr.id == item.pagamento?.id)
          ) {
            array[indexOperado].tabelas.pagamento.push({
              id: item.pagamento.id,
              item: item.pagamento,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.pagamento.some((arr) =>
              arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : ""
            );
          }

          if (
            item.carencia?.id &&
            !array[indexOperado].tabelas.carencia.some((arr) => arr.id == item.carencia?.id)
          ) {
            array[indexOperado].tabelas.carencia.push({
              id: item.carencia.id,
              item: item.carencia,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.carencia.some((arr) =>
              arr.id == item.carencia?.id ? arr.produto.push(item.nome) : ""
            );
          }

         

          item.tabelafaixas.map((item2) => {
            if (item2.rede?.id && ! array[indexOperado].tabelas.rede.some((arr) => arr.id == item2.rede?.id)) {
              array[indexOperado].tabelas.rede.push({ id: item2.rede.id, item: item2.rede, produto: [item.nome] });
            } else {
              array[indexOperado].tabelas.rede.some((arr) => arr.id == item2.rede?.id ? (!arr.produto.some((prod) => prod == item.nome) ? arr.produto.push(item.nome) : '') : "");
            }
          })

          if (
            item.reembolso?.id &&
            !array[indexOperado].tabelas.reembolso.some((arr) => arr.id == item.reembolso?.id)
          ) {
            array[indexOperado].tabelas.reembolso.push({ id: item.reembolso.id, item: item.reembolso, produto: [item.nome] });
          } else {
            array[indexOperado].tabelas.reembolso.some((arr) =>
              arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : ""
            );
          }
          if (
            item.grupoentidades?.id &&
            !array[indexOperado].tabelas.entidades.some((arr) => arr.id == item.grupoentidades?.id)
          ) {
            array[indexOperado].tabelas.entidades.push({
              id: item.grupoentidades.id,
              item: item.grupoentidades
              ,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.entidades.some((arr) =>
              arr.id == item.grupoentidades?.id ? arr.produto.push(item.nome) : ""
            );
          }


        }
      })

    });

    setInfo(array);
    if (status) {
      setModalVisualizarDetalheSelecionadoTotal(true)
    } else {
      setModalVisualizarDetalheSelecionado(true);
    }

    setLoadingDetalheSelecionado(false);
  };
  const Listagem = async () => {
    handleCarregamento(true);
    let dados = await Api.listagem();
    setList(dados.list);
    handleCarregamento(false);
  };
  const handleExcluir = async () => {
    handleCarregamento(true);
    let list = await Api.delete({ id: id });
    setList(list);
    setModalExcluir(false);
    handleCarregamento(false);
  };

  useEffect(()=>{
    setCalculoFiltro(calculo)
  },[calculo])
  const handleCalculo = async (id) => {
    handleCarregamento(true);
    let dados = await Api.calculo({ id: id, filtro1, filtro2, filtro3, filtro4, filtro5, filtro6 });

    let arrayValorOperadora = []
    if (dados.list?.list?.length > 0) {
      dados.list?.list?.map((item) => {
        item.tabelas.map((item2) => {

          item2?.tabelafaixas.map((item3) => {

            if (item3.valor && Number(item3.valor?.replace('.', '').replace(',', '.')) && !arrayValorOperadora.some((filtro) => filtro.valor == Number(item3.valor?.replace('.', '')?.replace(',', '.')) && filtro.operadora == item.nome)) {
              arrayValorOperadora.push({ valor: Number(item3.valor?.replace('.', '')?.replace(',', '.')), operadora: item.nome })
            }

          })

        })

      })
      arrayValorOperadora.sort((a, b) => a.valor - b.valor)

      let menorValor = arrayValorOperadora[0]?.valor
      let menorOperadora = arrayValorOperadora[0]?.operadora

      let intermediarioValor = arrayValorOperadora[Math.ceil((arrayValorOperadora?.length - 1) / 2)]?.valor
      let intermediarioOperadora = arrayValorOperadora[Math.ceil((arrayValorOperadora?.length - 1) / 2)]?.operadora


      let maiorValor = arrayValorOperadora[arrayValorOperadora?.length - 1]?.valor
      let maiorOperadora = arrayValorOperadora[arrayValorOperadora?.length - 1]?.operadora
      console.log("Dados",dados?.list?.list)
      setCalculo(dados?.list?.list);
      setModalVisualizarOperadora(true);

      let idtabelas = []
      dados.list.list.map((item) => {
        item.tabelas.map((item) => {
          idtabelas.push({ idtabela: item.id })
        })
      })
      setSelecteds((prevSelecteds) => [...prevSelecteds, ...idtabelas]);
      setSelectedsTotal((prevSelecteds) => [...prevSelecteds, ...idtabelas]);
      setCalculoDetalhe({ operadoraValorMaximo: maiorOperadora, operadoraValorMinimo: menorOperadora, operadoraValoIntermediario: intermediarioOperadora, valorMaximo: maiorValor, valorMinimo: menorValor, valorDoMeio: intermediarioValor, id: dados.list.cotacao.id, vidas: dados.list.cotacao?.vidas, nome: dados.list.cotacao?.info.descricao, cliente: dados.list.cotacao.cliente, estado: dados.list?.cotacao?.estado });
      handleCarregamento(false);
    }

  };

  const filtrar = async (nome) => {
    handleCarregamento(true);
    setLoading(true);
    let list = await Api.filtro({ nome: nome });
    setList(list.list);
    setLoading(false);
    handleCarregamento(false);
  };
  const handleDados = async (id) => {
    handleCarregamento(true);
    let dados = await Api.dados({ id: id });
    setDados({
      tipo: dados.list.tipo,
      ramo: dados.list.ramo,
      descricao: dados.list.descricao,
      pessoacontato: dados.list.pessoacontato,
      email: dados.list.email,
      telefone: dados.list.telefone,
      estado: dados.list.idestado,
      profissao: dados.list.idprofissao,
      titular: dados.list.titular,
      nomeempresa: dados.list.nomeempresa,
      tipocnpj: dados.list.tipocnpj,
      dependentes: dados.list.dependentes,
      areacomercializacao: dados.list.idareacomercializacao
    });

    let newFaixa = faixas;
    dados.list.cotacoesfaixas.forEach((elemento2) => {
      const elemento1 = newFaixa.find(
        (elemento1) => elemento1.id === elemento2.idfaixa
      );
      if (elemento1) {
        elemento1.value = elemento2.valor;
      }
    });

    setFaixas(newFaixa);
    let val1 = dados.list.cotacoesdependentes.map((item) => {
      return item.dependente;
    });
    setItens(val1);
    handleCarregamento(false);

  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "" || id,
      tipo: dados?.tipo ? dados.tipo : "1",
      tipoOperadora: dados?.tipoOperadora ? dados.tipoOperadora : "",
      descricao: dados?.descricao ? dados.descricao : "",
      pessoacontato: dados?.pessoacontato ? dados.pessoacontato : "",
      email: dados?.email ? dados.email : "",
      telefone: dados?.telefone ? dados.telefone : "",
      estados: dados?.estado ? dados.estado : "",
      profissao: dados?.profissao ? dados.profissao : "",
      titular: dados?.titular ? dados.titular : "",
      nomeempresa: dados?.nomeempresa ? dados.nomeempresa : "",
      tipocnpj: dados?.tipocnpj ? dados.tipocnpj : "",
      dependentes: dados?.dependentes ? dados.dependentes : "",
      faixas: null,
      dependentesarray: null
    },

    onSubmit: async (values) => {
      handleCarregamento(true);
      setLoading(true);
      values.faixas = faixas;
      values.dependentesarray = itens;
      if (id) {
        let json = await Api.editar(values);

        if (json.error) {
          setError(true);
          setResposta(true);
          setMsg(json.msg);
        } else {
          if (salvarVisualizar) {
            handleCalculo(json.id);
            setSalvarVisualizar(false);
            setRamo(ramo)
          }
          Listagem();
          setBuscar("");
          handleLimpar(true);
          setModalEditar(false);
        }
        setLoading(false);
      } else {
        let json = await Api.cadastro(values);

        if (json.error) {
          setError(true);
          setResposta(true);
          setMsg(json.msg);
        } else {
          if (salvarVisualizar) {
            handleCalculo(json.id);
            setSalvarVisualizar(false);
          }

          setError(false);
          setResposta(true);
          setMsg(json.msg);
          handleLimpar(false);
          Listagem();
        }
        setLoading(false);
      }
      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
      handleCarregamento(false);
    }
  });

  const handleLimpar = (status = false) => {
    if (status) {
      setResposta(false);
    }
    validation.values.descricao = "";
    validation.values.pessoacontato = "";
    validation.values.email = "";
    validation.values.telefone = "";
    validation.values.estados = "";
    validation.values.profissao = "";
    validation.values.titular = "";
    validation.values.nomeempresa = "";
    validation.values.tipocnpj = "";
    validation.values.dependentes = "";
    validation.values.areacomercializacao = "";
    validation.values.faixas = null;
    validation.values.dependentesarray = null;
    setItens([{}]);
    setInfoDependente([]);
    setInfoSelecionado({});
    setSelecteds([]);
    setDados({ tipo: "1", ramo: "", descricao: "", pessoacontato: "", email: "", telefone: "", estado: "", profissao: "", titular: "", nomeempresa: "", tipocnpj: "", dependentes: "", areacomercializacao: "" });
    let newFaixa = faixas;
    newFaixa.forEach((elemento2) => {
      elemento2.value = "";
    });
    setFaixas(newFaixa);
  };
  const [selecteds, setSelecteds] = useState([]);
  const handleMouseOver = (idtabela, id) => {
    let elemento = document.querySelector(`tr[id="${id}"]`);



    if (elemento) {
      let elemento2 = elemento.querySelector(`div[id="checkbox-${idtabela}"]`);

      if (elemento2) {
        elemento2.style.backgroundColor = "#0F4CBA";
      }

      elemento.style.backgroundColor = "#fff";
      elemento.style.border = "1px solid #0F4CBA";
      elemento.style.color = "#000";


    }

  };
  const handleMouseOut = (idtabela, id) => {
    let elemento = document.querySelector(`tr[id="${id}"]`);
    if (elemento && !selecteds.some((item) => item.tabela === idtabela && item.calculo === id)) {
      let elemento2 = elemento.querySelector(`div[id="checkbox-${idtabela}"]`);
      if (elemento2) {
        elemento2.style.backgroundColor = "#fff";
      }
      elemento.style.backgroundColor = "#fff";
      elemento.style.borderColor = "#fff";
      elemento.style.color = "#000";
    }

  };
  const handleClick = (idtabela) => {
    console.log('handleClick-> idtabela -> ', { idtabela }, selecteds.some((item) => item.idtabela == idtabela))
    if (selecteds.some((item) => item.idtabela == idtabela)) {
      let item = [...selecteds];
      let filtro = item.filter((item1) => item1.idtabela !== idtabela);
      setSelecteds(filtro);
    } else {
      let item = [...selecteds];
      item.push({ idtabela: idtabela });
      setSelecteds(item);
    }
    console.log('handleClick-> selecteds -> ', { selecteds })
  };
  const [valores, setValores] = useState()
  useEffect(() => {
    let valor = []

    calculoFiltro.map((item) => {
      //  handleClickOperadoraSelect(item)
      valor.push({ operadora: item.nome, valor: item?.tabelas.map((item2) => item2?.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : 1)) }, 0)).sort((a, b) => a - b)[0] })

      valor.push({ operadora: item.nome, valor: item?.tabelas.map((item2) => item2?.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : 1)) }, 0)).sort((a, b) => a - b)[item?.tabelas.map((item2) => item2?.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + parseFloat(element.valor?.replace(',', '.')) }, 0)).sort((a, b) => a - b).length - 1] })
    })
  
    if (valor?.length > 0) {
      valor = valor.sort((a, b) => a.valor - b.valor)
      let valorMaior = valor[valor.length - 1]?.valor
      let operadoraMaior = valor[valor.length - 1]?.operadora
      let valorMenor = valor[0]?.valor
      let operadoraMenor = valor[0]?.operadora
      let valorIntermediario = valor[Math.floor(valor.length / 2)]?.valor
      let operadoraIntermediario = valor[Math.floor(valor.length / 2)]?.operadora
      setValores({ valorMaior, operadoraMaior, valorMenor, operadoraMenor, valorIntermediario, operadoraIntermediario })
    }
  }, [calculoFiltro])

  const handleReCalcularValores = () => {
    let valor = []

    calculoFiltro?.filter((fill) => fill.status == 1).map((item) => {
      //  handleClickOperadoraSelect(item)

      valor.push({
        operadora: item.nome, valor: item?.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) =>
          item2?.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : 1)) }, 0)).sort((a, b) => a - b)[0]
      })

      valor.push({ operadora: item.nome, valor: item?.tabelas?.filter((tabela) => selecteds.some((select) => select.idtabela == tabela.id)).map((item2) => item2.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + (parseFloat(element.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? Number(element?.cotacoesfaixa?.valor) : 1)) }, 0)).sort((a, b) => a - b)[item.tabelas.map((item2) => item2.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + parseFloat(element.valor?.replace(',', '.')) }, 0)).sort((a, b) => a - b).length - 1] })


    })

    valor = valor.filter((v) => v.valor)

    if (valor?.length > 0) {
      valor = valor.sort((a, b) => a.valor - b.valor)
      let valorMaior = valor[valor.length - 1]?.valor
      let operadoraMaior = valor[valor.length - 1]?.operadora
      let valorMenor = valor[0]?.valor
      let operadoraMenor = valor[0]?.operadora
      let valorIntermediario = valor[Math.floor(valor.length / 2)]?.valor
      let operadoraIntermediario = valor[Math.floor(valor.length / 2)]?.operadora
      setValores({ valorMaior, operadoraMaior, valorMenor, operadoraMenor, valorIntermediario, operadoraIntermediario })
    } else {
      setValores({ valorMaior: 0, operadoraMaior: '', valorMenor: 0, operadoraMenor: '', valorIntermediario: 0, operadoraIntermediario: '' })
    }

  }
  const handleClickOperadoraSelect = async (item) => {
    setLoadingDetalheSelecionado(true);
    let dados = [...selecteds]
    item.tabelas.map((info) => {
      if (!dados.some((item) => item.idtabela == info.id)) {
        dados.push({ idtabela: info.id })
      }
    })
    setSelecteds(dados)
    //  await handleOperadora(item.id, { ids: item.tabelas.map((item2) => { return item2.id }) }, false);

  };
  useEffect(() => {
    handleReCalcularValores()
  }, [selecteds])
  const handleClickOperadoraDeselect = async (item) => {
    setLoadingDetalheSelecionado(true);
    let item5 = [...selecteds];
    console.log('antes', item5)
    item.tabelas.map((item2) => {

      if (item5.some((item4) => item4.idtabela == item2.id)) {
        item5 = item5.filter((item1) => item1.idtabela !== item2.id);
      }
    })
    console.log('dps', item5)
    //gerarTabelaSelecionada(item5, false)
    setSelecteds(item5);
    setLoadingDetalheSelecionado(false);

  };
  const handleVisualizarCotacoes = () => {
    gerarTabelaSelecionada(calculoFiltro, false);
  };
  const handleVisualizarCotacoesTodas = () => {
    gerarTabelaSelecionada(calculoFiltro, true);
  };
  const [area, setArea] = useState(1);

  useEffect(() => {
    handleEstados()
  }, [validation.values.tipoOperadora])
  return (
    <section className="about-two ">
      <div className="container" >
        <div className="" style={{ width: "100%", marginTop: '-50px' }}>

          {(loading || !calculoDetalhe.operadoraValorMaximo) && (
            <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img src={Icone} className="rotating-image" />
            </div>
          )}
          {(!loading && calculoDetalhe.operadoraValorMaximo) && (
            <div >



              <Visualizar handleCarregamento={handleCarregamento} setModalFiltro={setModalFiltro} modalFiltro={modalFiltro} handleOperadora={handleOperadora} calculoDetalhe={calculoDetalhe} calculo={calculo} calculoFiltro={calculoFiltro}
                modalVisualizarOperadora={true} setModalVisualizarOperadora={setModalVisualizarOperadora} handleVisualizarCotacoes={handleVisualizarCotacoes} ramo={ramo} handleClickOperadoraSelect={handleClickOperadoraSelect} handleClickOperadoraDeselect={handleClickOperadoraDeselect} loadingDetalhes={loadingDetalhes} valores={valores} infoDependente={infoDependente} setModalVisualizarDetalheSelecionadoTotal={setModalVisualizarDetalheSelecionadoTotal} handleVisualizarCotacoesTodas={handleVisualizarCotacoesTodas}  municipios={municipios}  setCalculoFiltro={setCalculoFiltro} />

              <VisualizarDetalhe handleCarregamento={handleCarregamento} handleClick={handleClick} handleMouseOut={handleMouseOut} handleMouseOver={handleMouseOver} selecteds={selecteds}
                setModalVisualizarDetalhe={setModalVisualizarDetalhe} infoDependente={infoDependente} faixas={faixas} info={info} modalVisualizarDetalhe={modalVisualizarDetalhe} calculoDetalhe={calculoDetalhe} handleVisualizarCotacoes={handleVisualizarCotacoes} loadingDetalhes={loadingDetalhes} />

              <VisualizarSelecionado id={id} handleCarregamento={handleCarregamento} selecteds={selecteds} setModalVisualizarDetalhe={setModalVisualizarDetalheSelecionado} infoDependente={infoDependente} faixas={faixas} info={info} modalVisualizarDetalhe={modalVisualizarDetalheSelecionado} calculoDetalhe={calculoDetalhe} handleClick={handleClick}
                gerarTabelaSelecionada={gerarTabelaSelecionada} loadingDetalhes={loadingDetalhes} setLoadingDetalhe={setLoadingDetalhe} setInfo={setInfo} />


              <VisualizarSelecionadoTodos id={id} handleCarregamento={handleCarregamento} selectedsTotal={selectedsTotal} setModalVisualizarDetalhe={setModalVisualizarDetalheSelecionadoTotal} infoDependente={infoDependente} faixas={faixas} info={info} modalVisualizarDetalhe={modalVisualizarDetalheSelecionadoTotal} calculoDetalhe={calculoDetalhe} handleClick={handleClick}
                gerarTabelaSelecionada={gerarTabelaSelecionada} loadingDetalhes={loadingDetalhes} setLoadingDetalhe={setLoadingDetalhe} setInfo={setInfo} />


              <Editar handleCarregamento={handleCarregamento} dados={dados} areaComercializacao={areaComercializacao} error={error} estados={estados} faixas={faixas} handleAdd={handleAdd} handleExit={handleExit} handleFaixasValue={handleFaixasValue} handleLimpar={handleLimpar} handleTipoValue={handleTipoValue} itens={itens} loading={loading} modalEditar={modalEditar} msg={msg} profissoes={profissoes} resposta={resposta} setModalEditar={setModalEditar} setResposta={setResposta} setSalvarVisualizar={setSalvarVisualizar} validation={validation} />

            

              <Agendar handleLimpar={handleLimpar} modalAgendar={modalAgendar} setModalAgendar={setModalAgendar} id={id} />
              <Agendamentos handleLimpar={handleLimpar} modalAgendamento={modalAgendamento} setModalAgendamento={setModalAgendamento} id={id}/>

            </div>

          )}


        </div>
      </div>
    </section>
  );
}

export default CotacoesPrecos;
