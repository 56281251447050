import React, { useState } from "react";
import { Col, Modal } from "reactstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { Filtros } from "./filtros";


export const Visualizar = ({ calculo, modalVisualizarOperadora, setModalVisualizarOperadora, calculoDetalhe, handleOperadora, handleVisualizarCotacoes, setModalFiltro, modalFiltro, handleCarregamento, ramo, handleClickOperadoraSelect, handleClickOperadoraDeselect, loadingDetalhes, valores, infoDependente, setModalVisualizarDetalheSelecionadoTotal, handleVisualizarCotacoesTodas, municipios, setCalculoFiltro ,calculoFiltro}) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  return (


    <div className="" style={{ marginTop: 100 }}>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', flexDirection: isDesktop ? 'row' : 'column' }}>

        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <p style={{ fontWeight: 100 }}>Calculo Nº {calculoDetalhe?.id}</p>
          <p style={{ fontWeight: 100 }}>Total de vidas: {calculoDetalhe?.vidas}</p>
          <p style={{ fontWeight: 100, width: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
            {`Cliente: ${calculoDetalhe?.nome ? calculoDetalhe.nome : ''}`}
          </p>

        </div>
        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100%' }}>
          <p style={{ fontWeight: 100 }}>
            {valores?.valorMenor?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            })}
            {' '}Menor Preço ({valores?.operadoraMenor})
          </p>
          <p style={{ fontWeight: 100 }}>
            {valores?.valorIntermediario?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            })}
            {' '}Preço Intermediário (
            {valores?.operadoraIntermediario})
          </p>
          <p style={{ fontWeight: 100 }}>
            {valores?.valorMaior?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            })}
            {' '}Maior Preço ({valores?.operadoraMaior})
          </p>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', marginTop: 15 }}>
        {modalFiltro &&
          <div style={{ width: 600 }}>
            <Filtros municipios={municipios} handleCarregamento={handleCarregamento} modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} calculo={calculo} setCalculoFiltro={setCalculoFiltro} ramo={ramo} />
          </div>
        }

      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', marginBottom: 15 }}>
        <button className="btn btn-primary" style={{ width: 200, marginRight: 10 }}
          onClick={() => {
            handleVisualizarCotacoesTodas();
          }} > Total <span style={{ color: 'red', fontWeight: 'bold' }}>{calculoFiltro.reduce((acumulador, element) => { return acumulador + element.tabelas.length }, 0)}</span> Cotações </button>
        <button className="btn btn-primary" style={{ width: 200, marginRight: 10 ,width: 200,marginRight: '10px',display: 'flex',justifyContent: 'center',alignItems: 'center'}}
          onClick={() => {
            setModalFiltro(!modalFiltro);
          }} > Filtrar Cotações {modalFiltro ? <RiArrowDropUpLine size={22} style={{paddingTop:4}} /> : <RiArrowDropDownLine size={22}  style={{paddingTop:4}}/>}   </button>
        <button className="btn btn-primary" onClick={handleVisualizarCotacoes} disabled={loadingDetalhes} style={{ width: 200 }} >
          {loadingDetalhes ? "Carregando..." : "Imprimir Cotações"} </button>
      </div>


      {calculoFiltro.length > 0 &&
        calculoFiltro?.sort((a, b) => b?.tabelas?.length - a?.tabelas?.length).sort((a, b) => a.status - b.status)?.map((item) => (
          <div key={'Item' + item.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 4, backgroundColor: '#AAD2F5', flexDirection: isDesktop ? 'row' : 'column-reverse', padding: 10, borderRadius: 4 }}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, paddingTop: '10px' }} >
              {item.status == 3 ? (
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
                  <input type="checkbox" className="form-control" disabled />
                  <label>Exibir</label>
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>

                  <input type="checkbox" defaultChecked={true} className="form-control" onChange={(e) => {
                    if (e.target.checked) {
                      handleClickOperadoraSelect(item)
                    } else {
                      handleClickOperadoraDeselect(item)
                    }
                  }} />
                  <label>Exibir</label>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }} >
              {item.status == 3 ? (
                <>
                  <button className="btn btn-danger">
                    Em atualização
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-primary"

                    onClick={() => {

                      handleOperadora(item);
                    }}
                  >
                    Ver {item.tabelas.length} {item.tabelas.length > 1 ? 'cotações' : 'cotação'}
                  </button>
                </>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flex: 1 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img src={`https://server.corpar.com.br/imagens/${item.imagem}`} alt="" width={'100px'} />

                <p style={{ textAlign: "center", fontSize: 16 }}> {item.nome}  </p>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <a className="btn btn-link" style={{ fontWeight: 100, color: '#1b2949' }} >
                Menor Preço:

                {item.tabelas.map((item2) => item2.precovida ? item2.precovida : item2.tabelafaixas.filter((item3) => item3.valor ).reduce((acumulador, element) => {
                  return acumulador +
                    (parseFloat(element?.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? parseFloat(element.cotacoesfaixa?.valor) : 1))
                }, 0)).sort((a, b) => a - b)[0]?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <br />


                Menor Maior: {item.tabelas.map((item2) => item2.precovida ? item2.precovida : item2.tabelafaixas.filter((item3) => item3.valor ).reduce((acumulador, element) => { return acumulador + parseFloat(element?.valor?.replace(',', '.')) }, 0)).sort((a, b) => a - b)[item.tabelas.map((item2) => item2.tabelafaixas.filter((item3) => item3.valor).reduce((acumulador, element) => { return acumulador + (parseFloat(element?.valor?.replace(',', '.')) * (element?.cotacoesfaixa?.valor ? parseFloat(element?.cotacoesfaixa?.valor) : 1)) }, 0)).sort((a, b) => a - b).length - 1]?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </a>
            </div>

          </div>
        ))}

    </div>
  );
};
