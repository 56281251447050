import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Col, Modal, Row } from "reactstrap";
import logo from "../../../assets/images/logos/logo.png";
import { RiDownloadCloudLine as Baixar } from "react-icons/ri";
import Icone from "../../../assets/icones/icone.png"
import { TbFilterDown } from "react-icons/tb";
import { TbFilterUp } from "react-icons/tb";
import { LiaEyeSolid } from "react-icons/lia";
import { LiaEyeSlash } from "react-icons/lia";
import { IoIosClose } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineEmail as Email } from "react-icons/md";
import { IoLogoWhatsapp as Wpp } from "react-icons/io5";
import { SlCalculator } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineDateRange } from "react-icons/md";
import logoAqui from "../../../assets/images/sualogoaqui.png";
import { IoMdPrint } from "react-icons/io";
import { jsPDF } from "jspdf";
import { Api } from "../../../api/apiCotacoes";
const getAuthUser = localStorage.getItem("authUser");
const obj =
  getAuthUser == "undefined" ||
    getAuthUser == null ||
    getAuthUser == "[object Object]"
    ? ""
    : JSON.parse(getAuthUser ? getAuthUser : "");

export const VisualizarSelecionado = ({ faixas, info, modalVisualizarDetalhe, setModalVisualizarDetalhe, infoDependente, calculoDetalhe, selecteds, handleClick, gerarTabelaSelecionada, handleCarregamento, loadingDetalhes, setLoadingDetalhe, setInfo,id }) => {
  const divRef = useRef(null);
  const [display, setDisplay] = useState('block')

  useEffect(() => {
    setDisplay('block')
  }, [modalVisualizarDetalhe])

  const dataAtual = () => {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const data = new Date();
    const mes1 = meses[data.getMonth()];
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // o valor do mês começa em 0, então é necessário adicionar 1
    const ano = data.getFullYear();
    return { data1: `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano}`, data2: `${mes1}/${ano}` }
  }

  const handleOrdenarMenor = () => {
    let selecionado = document.querySelector('button[id="1"]')
    let deselecionado = document.querySelector('button[id="2"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-primary'
      selecionado.style.backgroundColor = '#1b2949'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.backgroundColor = '#fff'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent
          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);
          linhasOrdenadas[i] = valor;
        }
      }

      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => a[1] - b[1]);

      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }
  const handleOrdenarMaior = () => {
    let selecionado = document.querySelector('button[id="2"]')
    let deselecionado = document.querySelector('button[id="1"]')
    if (selecionado && deselecionado) {
      selecionado.className = 'btn btn-primary'
      selecionado.style.backgroundColor = '#1b2949'
      deselecionado.className = 'btn btn-default'
      deselecionado.style.backgroundColor = '#fff'
    }
    if (document.querySelector('tbody[id="tabela"]')) {
      const tabela = document.querySelector('tbody[id="tabela"]');
      const linhas = tabela.querySelectorAll('.tr');
      const linhasOrdenadas = {};
      for (let i = 0; i < linhas.length; i++) {
        if (document.querySelectorAll('.valorTotal')[i]) {
          let dados = document.querySelectorAll('.valorTotal')[i].textContent
          dados = dados.replace('R$', '')
          dados = dados.replace('&nbsp;', '')
          dados = dados.replace('.', '')
          dados = dados.replace(',', '.')
          const valor = parseInt(dados);
          linhasOrdenadas[i] = valor;
        }
      }

      const linhasOrdenadasArray = Object.entries(linhasOrdenadas).sort((a, b) => b[1] - a[1]);

      for (let i = 0; i < linhasOrdenadasArray.length; i++) {
        const indice = linhasOrdenadasArray[i][0];
        tabela.appendChild(linhas[indice]);
      }
    }
    handleCarregamento(false)
  }
  useEffect(() => {

  }, [])
  const Compartilha = async () => {
    let response = await Api.compartilhar({ id })
    alert(response?.msg)
  }
  const [municipios, setMunicipios] = useState([])
  const [modalMunicipios, setModalMunicipios] = useState(false)
  const handleMunicipios = (municipio) => {
    setMunicipios(municipio)
    setModalMunicipios(true)
  }
  const handleVisualizar = (index, status) => {
    let dados = [...info]
    dados[index].visualizar = status
    setInfo(dados)
  }
  function formatarTelefone(telefone) {
    // Remove tudo que não for número
    telefone = telefone.replace(/\D/g, '');

    // Verifica se é celular ou fixo (9 dígitos para celular, 8 para fixo)
    if (telefone.length === 11) {
      // Celular com DDD: (XX) 9XXXX-XXXX
      return telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    } else if (telefone.length === 10) {
      // Fixo com DDD: (XX) XXXX-XXXX
      return telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    } else {
      return 'Número inválido';
    }
  }
  const printRef = useRef();

  const gerarPdf = () => {
    const doc = new jsPDF("p", "mm", "a4");

    // Gera o PDF a partir do conteúdo HTML
    doc.html(divRef.current, {
      callback: function (doc) {
        // Abre o PDF em uma nova aba do navegador
        const pdfBlob = doc.output("blob");
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, "_blank");
      },
      x: 10,
      y: 10,
      width: 180, // Largura máxima do conteúdo no PDF (180mm em A4)
      autoPaging: "text", // Isso tentará fazer o conteúdo fluir automaticamente nas páginas
    });
  };
  return (
    <Modal
      id="modal-center3"
      size="xl"
      isOpen={modalVisualizarDetalhe}
      centered={true}
      style={{ maxWidth: 1200}}  >
      <div className="" style={{width: '100%',overflowX: 'auto'}}>
        <Row >
          <div className="col-12" >
            <button
              type="button"
              onClick={() => {
                setModalVisualizarDetalhe(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ float: 'right', marginRight: 10 }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

        </Row>
        {loadingDetalhes ?
          <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src={Icone} className="rotating-image" />
          </div>

          :
          <>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <ReactToPrint
                trigger={() => <button className="btn btn-default" style={{ float: 'right', marginTop: 30 }} onMouseOut={() => { setDisplay('block') }} onMouseOver={() => { setDisplay('none') }}  >
                  <IoMdPrint size={30} color={'#5c5c5c'} />
                </button>}
                 content={() => divRef.current} />
              <a style={{ float: 'right', marginTop: 30 }} className="btn btn-default" onClick={() => { Compartilha() }} rel="noreferrer" target="_blank">
                <Email size={30} color={'#5c5c5c'} />
              </a>
              <a style={{ float: 'right', marginTop: 30 }} className="btn btn-default" href={`https://api.whatsapp.com/send?text=Olá,%20essa%20é%20a%20tabela%20personalizada%20que%20geramos%20para%20você,%20acesse%20esse%20link%20para%20visualizar%20:%20https%3A%2F%2F${window.location.href.replace('https://', '').replace('http://', '')}`} rel="noreferrer" target="_blank">
                <Wpp size={30} color={'#5c5c5c'} />

              </a>

            </div>

            {/* <Row>
              <Col col={12} >
                <div style={{ float: 'right', marginTop: 30 }}>
                  <button id='1' className="btn btn-default" onClick={() => {
                    handleCarregamento(true)
                    handleOrdenarMenor()
                  }} style={{ marginRight: 10 }} >Menor Preço  <TbFilterDown /></button>
                  <button id='2' className="btn btn-default" onClick={() => {
                    handleCarregamento(true)
                    handleOrdenarMaior()
                  }} style={{ marginRight: 10 }} >Maior Preço <TbFilterUp />
                  </button>
                  <ReactToPrint
                    trigger={() => <button className="btn btn-primary" onMouseOut={() => { setDisplay('block') }} onMouseOver={() => { setDisplay('none') }} style={{ marginRight: 10 }} ><Baixar size={20} /></button>}
                    content={() => divRef.current} />
                </div>
              </Col>
            </Row> */}






            <div className="modal-body" id='minha-div' ref={divRef} >

              <Col lg={12}>

                <div style={{ backgroundColor: '#4549A2', fontSize: 12, textAlign: 'center', color: '#fff', fontWeight: 'bold' }} ></div>
              </Col>










              <table className="table"  >

                <tbody id='tabela' style={{ display: "flex",padding:30, flexDirection: "column" }}>

                  {info?.length > 0 && info?.map((operadora, index) => (
                    <>
                      {index != 0 && <div style={{ pageBreakAfter: 'always' }} ></div>}
                      <div style={{ display: index == 0 ? 'block' : (display == 'none' ? 'block' : 'none') }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                          <div style={{ width: 250 }} >

                            {obj.foto ?
                              <img src={`https://server.corpar.com.br/imagens/${obj.foto}`} alt="" width='250px' />
                              :
                              <img src={logoAqui} alt="" width='250px' />
                            }

                          </div>
                          <div style={{ width: 350 }}>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaRegUser size={12} style={{ marginRight: 5 }} /> Corretor: {obj.nome} </p>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaWhatsapp size={12} style={{ marginRight: 5 }} /> Celular: {formatarTelefone(obj.dados.celular)}</p>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineEmail size={12} style={{ marginRight: 5 }} /> Email: {obj.email}</p>
                            {/* <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>{calculoDetalhe.cliente ? `Cliente: ${calculoDetalhe.cliente}` : ''} </p> */}
                          </div>
                          <div style={{ width: 400 }} >
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><IoLocationOutline size={12} style={{ marginRight: 5 }} />{calculoDetalhe?.estado ? `Estado: ${calculoDetalhe.estado}` : ''}</p>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineDateRange size={12} style={{ marginRight: 5 }} /> Gerado em {dataAtual().data1}</p>
                          </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 20, marginBottom: 10, textAlign: 'justify', lineHeight: 1.4 }}>
                          <div style={{ textAlign: 'justify', fontSize: 15 }} >{calculoDetalhe.cliente ? calculoDetalhe.cliente : ''} Agradecemos a oportunidade. Seguem as simulações dos planos para análise. As condições apresentadas, como preços, regras, contratuais e comerciais, possuem validade e estão sujeitas à confirmação no processo de fechamento dos contratos pelas operadoras/seguradoras.</div>

                        </div>
                      </div>
                      {/* style={{display:index==0?'block':(display=='none'?'block':'none')}}  */}

                      {selecteds.some((select) => operadora.tabelas.produto.some((som) => som.id == select.idtabela)) &&
                        (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 2 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                              <img src={`https://server.corpar.com.br/imagens/${operadora?.imagem}`} width='200px' style={{ margin: 'auto', marginRight: 10 }} />


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-10px' }} >
                              {/* {operadora.operadora} */}
                            </div>


                            <div style={{ position: "absolute", right: '30px', display }} >
                              {operadora.visualizar ?
                                <LiaEyeSolid onClick={() => { handleVisualizar(index, false) }} />
                                :
                                <LiaEyeSlash onClick={() => { handleVisualizar(index, true) }} />
                              }
                            </div>




                          </div>
                        )
                      }

                      {(display == 'none' ? true : operadora.visualizar) && operadora.tabelas.produto.map((item) => (

                        <>

                          {selecteds.some((select) => select.idtabela == item?.id) &&

                            (<tr key={'tr-' + item.id} className="tr" id={item?.faixas.map((item3) => { return item3.id }).join('&')} style={{ display: "flex", flexDirection: 'column', marginBottom: 10 }} >

                              <tr style={{ textAlign: 'center' }}  >
                                <button style={{ position: 'absolute', right: 30, display: display, color: '#D75350' }} className='btn btn-default'  >
                                  <IoIosClose size={20} onClick={() => { handleClick(item.id) }} />

                                </button>



                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, backgroundColor: 'rgb(204, 204, 204,0.5)', borderRadius: 6 }}>

                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'center', width: '100%', marginBottom: 0, lineHeight: 1.2 }} >

                                      {/* <input type="checkbox"  id={`checkbox-${item.id}`}  style={{float:'left',marginLeft:50}}  />  */}

                                      {operadora.operadora?.toUpperCase()} <br /> {item.nome}<br /> {item.cobertura ? `${item.cobertura}` : ''}  {item.vidas} {item.acomodacao ? `- ${item.acomodacao} - ` : ''} {(item.coparticipacao == 'Sim' || item.coparticipacao == "Total" || item.coparticipacao == "Parcial") ? `Com coparticipacão ${item.coparticipacao == "Total" || item.coparticipacao == "Parcial" ? `(${item.coparticipacao})` : ""} ${item.percentual ? 'de  ' + item.percentual + '%' : ''}` : 'Sem coparticipacão'}</p>
                                  </div>



                                </div>


                                <table className="" style={{ width: '100%' }}  >
                                  <tbody  >
                                    <tr style={{ padding: 1, height: 40, display: 'flex', justifyContent: 'space-around' ,border:'none'}}>


                                    {item?.faixas.sort((a,b)=> Number(a.faixa?.nome?.split('-')[0]) - Number(b.faixa?.nome?.split(' ')[0])).filter((filter) => filter.cotacoesfaixa?.valor)?.map((item, index) => (

                                        // {info?.produto && info?.produto[0]?.faixas?.sort((a, b) => parseFloat(a?.valor?.replace(',', '.')) - parseFloat(b?.valor?.replace(',', '.')))?.map((item, index) => (


                                        <div key={item.id} style={{ textAlign: 'center', borderWidth: 1, padding: 5, border: 0, width: 80 }} >


                                          <p style={{ fontWeight: 'bold', fontSize: 15, height: 6, lineHeight: 1 }} > {item.faixa?.nome ? item.faixa.nome?.replace('-', ' à ') + "" : ''} <br /> {parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor.replace(',', '.') : (infoDependente.length > 0 && infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1)} vida(s)</p>

                                        </div>

                                      ))}

                                      <div style={{ textAlign: 'center', borderWidth: 1, padding: 5, lineHeight: 1, width: 80 }} >
                                        <p style={{ fontWeight: 'bold', fontSize: 15, height: 8 }} >Total  <br />  {calculoDetalhe?.vidas > 0 ? calculoDetalhe.vidas : 1} vida(s)</p>

                                      </div>
                                    </tr>
                                  </tbody>
                                </table>

                              </tr>
                              {(item.faixas?.length > 0 || item.precovida) &&
                                <tr style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}   >
                                  <td style={{ textAlign: 'center', padding: 5, width: '100%' }} >



                                    <table className="table" style={{ cursor: 'pointer' }}>
                                      <tbody>

                                        {item.status == 2 ?
                                          <tr style={{ textAlign: 'center', borderWidth: 1 }}>
                                            Em atualização
                                          </tr>
                                          :
                                          <>
                                            {(item.faixas?.length > 0 || item.precovida) &&
                                              <tr >

                                                {item.faixas?.length ?
                                                  <>
                                                    {item?.faixas.filter((filter) => filter.cotacoesfaixa?.valor)?.map((item2) => (
                                                      <>

                                                        {item2.status ?
                                                          <td style={{ textAlign: 'center', border: 0, color: '#092140', padding: 5, flex: 1, width: 95 }} >
                                                            <p style={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1, height: 8, color: '#092140' }} >
                                                              {(
                                                                ((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * (item2.statuscoeficiente && item2.coeficiente ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : 0))

                                                                * parseFloat(item2?.cotacoesfaixa?.valor ? item2?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente?.length > 0 && infoDependente?.map((elemento) => (elemento <= item2?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item2?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                                              <span style={{ fontSize: 12 }} >

                                                                <br />{((parseFloat(item2.valor ? item2.valor?.replace(',', '.') : 0) * (item2.statuscoeficiente && item2.coeficiente ? parseFloat(item2.coeficiente / 100) : 1)) + parseFloat(item2.odonto ? item2.odonto?.replace(',', '.') : '0'))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br /> p/vida</span>
                                                            </p>


                                                          </td>
                                                          :
                                                          <td style={{ textAlign: 'center', border: 0, flex: 1, width: 95 }} >
                                                            <p style={{ fontWeight: 'bold', color: 'red', fontSize: 15, height: 8 }} >Suspenso</p>

                                                          </td>}
                                                      </>

                                                    ))}
                                                    <td style={{ textAlign: 'center', border: 0, color: '#092140', padding: 5, flex: 1, width: 95, lineHeight: 1 }} >
                                                      <p style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center', marginBottom: 0 }} className='valorTotal' >

                                                        {Number(item?.faixas?.reduce((acumulador, item) => {
                                                          return  acumulador + (((parseFloat(item.valor ? item.valor?.replace(',', '.') : 0) * (item?.statuscoeficiente && item?.coeficiente ? parseFloat(item?.coeficiente / 100) : 1)) + parseFloat(item.odonto ? item.odonto?.replace(',', '.') : '0'))
                                                            * parseFloat(item?.cotacoesfaixa?.valor ? item?.cotacoesfaixa?.valor?.replace(',', '.') : (infoDependente?.length > 0 && infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0)) ? infoDependente?.map((elemento) => (elemento <= item?.faixa?.max && elemento >= item?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : 1))
                                                        }, 0))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                      </p>
                                                      <span style={{ fontSize: 12 }} >Valor Total </span>
                                                    </td>
                                                  </>
                                                  :
                                                  <>
                                                    {item.precovida &&
                                                      <>
                                                        <td style={{ textAlign: 'center', borderWidth: 1, padding: 5, flex: 1 }} >
                                                          <p style={{ fontSize: 12 }} >R$ {item.precovida} p/vida</p>
                                                        </td>
                                                        <td style={{ textAlign: 'center', borderWidth: 1, padding: 5, flex: 1 }} >
                                                          <p style={{ fontWeight: 'bold', fontSize: 15 }} className='valorTotal' >{(parseFloat(item.precovida.replace(',', '.')) * parseFloat(calculoDetalhe.vidas))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                        </td>
                                                      </>

                                                    }

                                                  </>
                                                }


                                              </tr>
                                            }
                                          </>

                                        }

                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              }


                            </tr>
                            )
                          }

                        </>
                      ))}




                      {operadora.visualizar && operadora.tabelas?.observacoes?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{  breakInside: 'avoid',textAlign: 'center', fontSize: 16, textTransform: 'uppercase', backgroundColor: '#fff', width: '210px' }}>Observações Importantes</p>
                          <p style={{ fontSize: 12, color: 'red', textAlign: 'center', lineHeight: 1.3 }}>"Esta simulação poderá variar de acordo com o perfil do cliente e a critério da operadora.<br /> Preços, condições e critérios de aceitação da proposta estão sujeitos a analise e confirmação no momento da implantação do contrato."</p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.observacoes?.map((item, index) => (
                                <>
                                  <p>
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>
                                  <p style={{ fontSize: 16, marginBottom: 40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                                  </p>
                                </>
                              ))}
                            </div>
                          </Col>
                        </div>}

                      {operadora.visualizar && operadora.tabelas?.rede?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '180px' }}>Rede de Atendimento</p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.rede?.map((item) => (
                                <>
                                  <p>
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>
                                  <p style={{ fontSize: 16, marginBottom: 40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                                  </p>
                                </>
                              ))}
                            </div>
                          </Col>
                        </div>}


                      {operadora.visualizar && operadora.tabelas?.reembolso?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '250px' }}>Diferenciais e Reembolsos</p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.reembolso?.map((item) => (
                                <>
                                  <p>
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>
                                  <p style={{ fontSize: 16, marginBottom: 40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                                  </p>
                                </>
                              ))}
                            </div>
                          </Col>
                        </div>}
                      {operadora.visualizar && operadora.tabelas?.carencia?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '150px' }}>Carências</p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: "none", borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.carencia?.map((item) => (
                                <>
                                  <p>
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>
                                  <p style={{ fontSize: 16, marginBottom: 40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                                  </p>
                                </>

                              ))}

                            </div>
                          </Col>
                        </div>
                      }



                      {operadora.visualizar && operadora.tabelas?.dependente?.length > 0 &&
                        <div style={{ breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '140px' }}>Dependentes</p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}   >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.dependente?.map((item) => (
                                <>
                                  <p>
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>
                                  <p style={{ fontSize: 16, marginBottom: 40 }}>

                                    <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                                  </p>
                                </>

                              ))}

                            </div>
                          </Col>
                        </div>
                      }


                      {operadora.visualizar && operadora.tabelas?.documento?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '230px' }}>Documentos Necessários</p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.documento?.map((item) => (
                                <>
                                  <p >
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>
                                  <p style={{ fontSize: 16, marginBottom: 40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                                  </p>
                                </>


                              ))}

                            </div>
                          </Col>
                        </div>
                      }

                      {operadora.visualizar && operadora.tabelas?.pagamento?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '230px' }}>Formas de Pagamentos</p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.pagamento?.map((item) => (
                                <>
                                  <p >
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>
                                  <p style={{ fontSize: 16, marginBottom: 40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                                  </p>
                                </>

                              ))}

                            </div>
                          </Col>
                        </div>
                      }

                      {operadora.visualizar && operadora.tabelas?.entidades?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '200px' }}>Entidades e Profissões  </p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.entidades?.map((item) => (
                                <div key={item} style={{ marginBottom: 20 }}>
                                  <p >
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>

                                  <span>{item.item.gruposentidades.map((item4) => (

                                    <span key={item4}><span style={{ color: '#092140', fontWeight: 'bold', fontSize: 16 }}>{item4?.entidade?.nome}:</span>  {item4.entidadesprofisso?.profisso?.nome} - </span>

                                  ))}</span>

                                </div>

                              ))}

                            </div>
                          </Col>
                        </div>
                      }


                      {operadora.visualizar && operadora.tabelas?.areacomercializacao?.length > 0 &&
                        <div style={{  breakInside: 'avoid',border: "2px solid #ccc", margin: 20,marginTop:15,height:'100%',padding:10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                          <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', width: '310px' }}>Áreas de Comercialização e Atendimento  </p>
                          <Col lg={12}>
                            <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                              <span style={{ color: '#092140' }} >{operadora.operadora}</span><br />
                              {operadora.tabelas && operadora.tabelas?.areacomercializacao?.map((item) => (
                                <div key={item} style={{ marginBottom: 20 }} >
                                  <p>
                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                                  </p>


                                  {item?.municipios.length > 0 && item?.municipios?.filter((item, index, self) => index === self?.findIndex((m) => m.idestado === item.idestado)).map((muni) => (
                                    <>

                                      {!operadora.tabelas?.areacomercializacao[0]?.descricoes?.some((element) => (Number(element?.idestado) == muni.idestado && element.descricao)) ?

                                        <>
                                          {item?.municipios.map((item3, index) => (
                                            <>
                                              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3?.nome)} -` }}  ></span>
                                            </>
                                          ))}
                                        </>
                                        :
                                        <>
                                          <span key={`Comercializacao-`}  >{info?.areacomercializacao[0].descricoes.find((element) => Number(element.idestado) == muni.idestado)?.descricao}</span><br />
                                          <a style={{ textDecoration: 'underline', textAlign: 'justify' }} onClick={() => { handleMunicipios(item?.municipios) }} > Ver municípios</a>
                                        </>
                                      }
                                    </>
                                  ))

                                  }




                                  <br />
                                  <p  >{item.descricao}</p>

                                </div>

                              ))}

                            </div>
                          </Col>
                        </div>
                      }


                    </>
                  )

                  )}



                </tbody>
              </table>




            </div >

          </>}
      </div>



      <Modal isOpen={modalMunicipios} centered={true}>
        <div className="modal-header" style={{ border: 'none' }}>

          <button
            type="button"
            onClick={() => {
              setModalMunicipios(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <p style={{ color: '#092140', textAlign: 'center' }}>Municípios</p>

          <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
            {municipios.map((item3, index) => (
              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.municipio.nome)} -` }}  ></span>
            ))}
          </Row>
        </div>

      </Modal>
    </Modal >
  )
};
